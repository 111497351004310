<template>
	<TooltipSubmenuButton v-model="langMenu">
		<template #text>
			{{ t('UserDropdown.language') }}
		</template>
		<template #icon>
			<Component :is="selectedLangIcon" />
		</template>
		<template #content>
			<TooltipButton
				v-for="lang in langs"
				@click="selectLang(lang)"
				:left="!right"
				:active="lang.code === selectedLang"
			>
				<template #text>
					{{ lang.name }}
				</template>
				<template #icon>
					<Component :is="lang.icon" />
				</template>
			</TooltipButton>
		</template>
	</TooltipSubmenuButton>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import TooltipSubmenuButton from './TooltipSubmenuButton.vue'
import { languages, useLocaleAsync } from '../composables/useLocale'
import { useI18n } from 'vue-i18n'
import TooltipButton from './TooltipButton.vue'

const { t, locale } = useI18n()

defineProps<{
	right?: boolean
}>()

const selectedLang = computed(() => locale.value)
const langs = ref(languages())

const selectedLangIcon = computed(
	() => langs.value.find((lang) => lang.code === selectedLang.value)?.icon
)

const langMenu = ref(false)

const selectLang = (lang: { code: string }) => {
	langMenu.value = false
	useLocaleAsync(lang.code)
}
</script>
