<template>
	<Component
		:is="as"
		:type="as === 'button' ? 'button' : undefined"
		class="group/btn peer flex h-9.5 w-full cursor-pointer items-center gap-2 rounded-lg px-3 hover:bg-fill-tertiary focus:outline-none active:bg-fill-quaternary disabled:cursor-progress peer-aria-expanded:hidden"
		:class="{ 'justify-between': !left }"
		@click="click"
		:disabled="disabled"
		:tabindex="active ? '-1' : undefined"
	>
		<div
			class="truncate text-sm font-medium text-label-primary"
			:class="{
				'text-pink': active,
				'text-label-primary': color === 'none' && !active,
				'text-lava': color === 'lava' && !active,
				'order-2': left
			}"
		>
			<slot name="text" />
		</div>
		<div
			class="*:h-5 *:w-5 empty:hidden"
			:class="{
				'text-pink': active,
				'text-label-secondary': color === 'none' && !active,
				'text-lava': color === 'lava' && !active,
				'order-1': left
			}"
		>
			<slot name="icon" />
		</div>
	</Component>
</template>
<script setup lang="ts">
withDefaults(
	defineProps<{
		disabled?: boolean
		as?: 'button' | 'a' | 'RouterLink'
		color?: 'none' | 'lava'
		active?: boolean
		left?: boolean
	}>(),
	{
		as: 'button',
		color: 'none'
	}
)

const emit = defineEmits<{
	(e: 'click'): void
}>()

const click = () => emit('click')
</script>
