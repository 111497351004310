<template>
	<Menu class="relative" as="div">
		<slot name="anchor" />
		<BaseCard
			:as="MenuItems"
			class="!absolute z-20 max-w-fit overflow-hidden rounded-xl focus-visible:outline-pink focus-visible:ring-4 focus-visible:ring-pink/20 enabled:focus-visible:outline-offset-0 dark:!ring-0"
			:class="[position]"
		>
			<slot name="content" />
		</BaseCard>
	</Menu>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import BaseCard from './BaseCard.vue'
import { Menu, MenuItems } from '@headlessui/vue'

const props = withDefaults(
	defineProps<{
		align?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'
	}>(),
	{
		align: 'bottom-left'
	}
)

const position = computed(() => {
	if (props.align === 'bottom-left') {
		return 'left-0 mt-2.5'
	}
	if (props.align === 'bottom-right') {
		return 'right-0 mt-2.5'
	}
	if (props.align === 'top-left') {
		return 'bottom-full left-0 mb-2.5'
	}
	if (props.align === 'top-right') {
		return 'bottom-full right-0 mb-2.5'
	}
})
</script>
