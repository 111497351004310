<template>
	<div
		class="group flex items-center"
		:class="{ 'cursor-pointer': !disabled, 'cursor-not-allowed': disabled }"
	>
		<div
			class="relative flex h-6 w-6 flex-shrink-0 items-center justify-center overflow-hidden rounded-lg"
			:class="{
				'bg-fill-primary outline outline-1 -outline-offset-1 outline-separator-secondary group-hover:bg-fill-secondary dark:group-hover:bg-fill-tertiary dark:group-active:bg-fill-quaternary':
					!model && !disabled,
				'bg-pink': model && !disabled,
				'bg-fill-quaternary': disabled
			}"
		>
			<ButtonHover v-if="!disabled" />
			<input
				:id="id"
				v-model="model"
				type="checkbox"
				class="absolute bottom-0 left-0 right-0 top-0 h-full w-full opacity-0"
				:class="{ 'cursor-pointer': !disabled, 'cursor-not-allowed': disabled }"
				:disabled="disabled"
			/>
			<CheckIcon class="h-5 w-5 text-white" v-if="model" />
		</div>
		<label
			class="flex-1 select-none py-0.5 pl-2.5 align-middle text-sm font-normal text-label-secondary *-[a]:font-medium *-[a]:text-pink *-[a]:no-underline empty:hidden"
			:for="id"
			:class="{
				'text-lava': error,
				'cursor-pointer': !disabled,
				'cursor-not-allowed': disabled
			}"
		>
			<slot name="label" />
		</label>
	</div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import CheckIcon from '../assets/icons/check.svg'
import ButtonHover from './ButtonHover.vue'

const id = ref(`${Date.now().toString()}-${Math.floor(Math.random() * 100000)}`)

defineProps<{
	error?: string
	disabled?: boolean
}>()

const model = defineModel<boolean>()
</script>
