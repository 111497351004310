<template>
	<TooltipButton
		:disabled="disabled"
		:as="as"
		:color="color"
		v-if="!model"
		@click="click"
		:left="left"
		v-bind="$attrs"
	>
		<template #text>
			<slot name="text" />
		</template>
		<template #icon>
			<slot name="icon" />
		</template>
	</TooltipButton>
	<div
		class="peer peer-aria-expanded:hidden"
		aria-expanded="true"
		v-if="model"
		v-bind="$attrs"
	>
		<TooltipButton as="button" color="none" @click="back" left>
			<template #text>
				{{ t('TooltipSubmenuButton.back') }}
			</template>
			<template #icon>
				<ArrowLeftIcon />
			</template>
		</TooltipButton>
		<slot name="content" />
	</div>
</template>
<script setup lang="ts">
import TooltipButton from './TooltipButton.vue'
import { useI18n } from 'vue-i18n'
import ArrowLeftIcon from '../assets/icons/arrow-left.svg'

const { t } = useI18n()

const model = defineModel<boolean>({ default: false })

defineProps<{
	disabled?: boolean
	as?: 'button' | 'a' | 'RouterLink'
	color?: 'none' | 'lava'
	left?: boolean
}>()

const emit = defineEmits<{
	(e: 'click'): void
	(e: 'back'): void
}>()

const click = () => {
	model.value = true
	emit('click')
}

const back = () => {
	model.value = false
	emit('back')
}
</script>
