import { useMutation, useQuery, useSubscription } from '@vue/apollo-composable'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useModalStore } from './modal'
import { graphql } from '../gql'
import { AuthProvider } from '../gql/graphql'
import { useRouter } from 'vue-router'
import { useAlertStore } from './alert'
import { useI18n } from 'vue-i18n'
import { ApolloError } from '@apollo/client/errors'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '../utils/apollo'
import { useErrorCatcher } from '../composables/useErrorCatcher'

type User = {
	uuid: string
	totalCredits: number
	name: string
	emailVerified: boolean
	avatar?: string | null
	provider: AuthProvider
	acceptedTerms: boolean
	intercomHash?: string | null
}

export const useUserStore = defineStore('user', () => {
	const { t } = useI18n()

	const {
		result: query,
		onError,
		loading,
		refetch
	} = provideApolloClient(apolloClient)(() =>
		useQuery(
			graphql(`
				query getUser {
					current {
						uuid
						totalCredits
						name
						emailVerified
						avatar
						provider
						acceptedTerms
						intercomHash
					}
				}
			`),
			null,
			{
				errorPolicy: 'all'
			}
		)
	)
	const {
		restart: restartUpdate,
		onResult: onUserUpdate,
		stop: stopUpdate
	} = provideApolloClient(apolloClient)(() =>
		useSubscription(
			graphql(`
				subscription userUpdate {
					userUpdate {
						uuid
						totalCredits
						name
						emailVerified
						avatar
						provider
						acceptedTerms
						intercomHash
					}
				}
			`)
		)
	)

	const {
		restart: restartBalance,
		onResult: onBalanceUpdate,
		stop: stopBalance
	} = provideApolloClient(apolloClient)(() =>
		useSubscription(
			graphql(`
				subscription balanceUpdate {
					balanceUpdate {
						message
						totalCredits
					}
				}
			`)
		)
	)

	const restart = () => {
		restartUpdate()
		restartBalance()
	}
	const stop = () => {
		stopUpdate()
		stopBalance()
	}

	const firstUpdate = ref(true)
	const current = ref<User>()
	const signUpEmail = ref()

	const setSignUpEmail = (email: string) => {
		signUpEmail.value = email
	}

	// called when current user is set
	const onLogin = () => {
		if (!current.value) {
			return
		}

		const url = new URL(location.href)
		const hasAuth = url.searchParams.has('auth')

		if (hasAuth) {
			url.searchParams.delete('auth')
			router.replace({ query: Object.fromEntries(url.searchParams) })
		}

		if (localStorage.getItem('censor')) {
			localStorage.removeItem('censor')
		}

		// open terms modal if user has not accepted terms
		if (!current.value.acceptedTerms) {
			open('TermsOfService')
		}

		// open verify modal if user is not verified
		if (!current.value.emailVerified && current.value.provider === 'email') {
			open('VerifyEmail')
		}

		if (window.Intercom && current.value.intercomHash) {
			window.Intercom('update', {
				user_hash: current.value.intercomHash,
				name: current.value.name,
				user_id: current.value.uuid
			})
		}
	}

	const onNotLoggedIn = () => {
		const url = new URL(location.href)
		const hasAuth = url.searchParams.has('auth')

		if (hasAuth) {
			open('SignIn')
			url.searchParams.delete('auth')
			router.replace({ query: Object.fromEntries(url.searchParams) })
		}
	}

	const setCurrent = (user?: User) => {
		if (!current.value && user) {
			current.value = user

			onLogin()

			// if signed in, try connecting to subscription
			if (!firstUpdate.value) {
				restart()
			}
		}

		if (!current.value && !user && firstUpdate.value) {
			onNotLoggedIn()
		}

		firstUpdate.value = false
		current.value = user
	}

	const setTotalCredits = (value: number) => {
		if (!current.value) {
			return
		}

		current.value.totalCredits = value
	}

	const { mutate } = provideApolloClient(apolloClient)(() =>
		useMutation(
			graphql(`
				mutation logout {
					logout
				}
			`)
		)
	)

	const router = useRouter()

	const logout = async () => {
		console.log('logout')

		try {
			setCurrent()
			stop()
			router.push('/undress')
			await mutate()
		} catch (err) {
			console.error(err)
			// silent
		}
	}

	const { open } = useModalStore()

	// open verify modal if user is not verified
	if (
		query.value?.current &&
		!query.value?.current?.emailVerified &&
		query.value?.current?.provider === 'email'
	) {
		open('VerifyEmail')
	}

	watch(query, () => setCurrent(query.value?.current ?? undefined))
	onUserUpdate((result) => setCurrent(result.data?.userUpdate))

	const alert = useAlertStore()

	onBalanceUpdate((result) => {
		if (!result.data?.balanceUpdate) {
			return
		}
		if (!current.value) {
			return
		}

		const { message, totalCredits } = result.data.balanceUpdate

		const diff = totalCredits - current.value.totalCredits

		if (message === 'refill' && diff) {
			alert.push(t('User.balanceRefill', [diff]), 'green')
		}
		if (message === 'refund' && diff) {
			alert.push(t('User.balanceRefund', [diff]), 'green')
		}

		setTotalCredits(totalCredits)
	})

	const emailRetryAvailable = ref(true)

	const disableEmailRetry = () => (emailRetryAvailable.value = false)

	const setEmailVerified = (value: boolean) => {
		if (!current.value) {
			return
		}

		current.value.emailVerified = value
	}

	const setAcceptedTerms = (value: boolean) => {
		if (!current.value) {
			return
		}

		current.value.acceptedTerms = value
	}

	// process censoring
	const url = new URL(location.href)
	const censored = url.searchParams.has('censor')

	if (censored) {
		url.searchParams.delete('censor')
		router.replace({ query: Object.fromEntries(url.searchParams) })
		localStorage.setItem('censor', '1')
	}

	const censor = computed(
		() => !current.value && (censored || !!localStorage.getItem('censor'))
	)

	const { handle } = useErrorCatcher()

	onError((err: ApolloError) => handle(err))

	return {
		current,
		loading,
		setEmailVerified,
		setAcceptedTerms,
		setCurrent,
		signUpEmail,
		setSignUpEmail,
		logout,
		refetch,
		emailRetryAvailable,
		disableEmailRetry,
		setTotalCredits,
		censor
	}
})
